.back-link {
    font-size: 80%;
    a {
        color: currentColor;
	 svg {
            fill: currentColor;
        }
    }
}

