.tags-list a {
  margin-right: 0.5em;
  opacity: 0.75;
  white-space: nowrap;
}

.tags-list a .tag-count {
  background: $link-color;
  border-radius: 1000px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.75em;
  margin-left: 0.25em;
  padding-left: 0.6em;
  padding-right: 0.6em;
}

.tags-list a:hover {
  opacity: 1;
  text-decoration: none;
}

@keyframes posts-for-tag-fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

// Display only if targeted
.posts-for-tag {
  display: none;

  &:target {
    animation: posts-for-tag-fade-in 0.6s ease-in-out;
    display: block;
  }
}
