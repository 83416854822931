// Body resets
//
// Update the foundational and global aspects of the page.

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  font-family: $root-font-family;
  font-size: $root-font-size;
  line-height: $root-line-height;

  @media (min-width: $large-breakpoint) {
    font-size: $large-font-size;
  }
}

body {
  text-size-adjust: 100%;
}

main,
article,
section {
  display: block;
}

// No `:visited` state is required by default (browsers will use `a`)
a {
  color: $link-color;
  text-decoration: none;

  // `:focus` is linked to `:hover` for basic accessibility
  &:hover,
  &:focus {
    text-decoration: underline;
  }

  strong {
    color: inherit;
  }
}

img {
  border-radius: 5px;
  display: block;
  height: auto; // prevent max-width from squishing images with defined height
  margin: 0 0 1rem;
  max-width: 100%;
}

table {
  border: 1px solid $border-color;
  border-collapse: collapse;
  font-size: 85%;
  margin-bottom: 1rem;
  width: 100%;
}

td,
th {
  border: 1px solid $border-color;
  padding: 0.25rem 0.5rem;
}

th {
  text-align: left;
}

tbody tr:nth-child(odd) td,
tbody tr:nth-child(odd) th {
  background-color: $gray-1;
}

button,
input[type='text'],
input[type='email'],
input[type='search'],
input[type='submit'] {
  border: 1px solid $border-color;
  border-radius: $border-radius;
  padding: $padding-v $padding-h;
}

button,
input[type='submit'] {
  background: transparent;
  border-color: $border-color;
  color: $link-color;
  cursor: pointer;
  transition:
    color 0.6s ease-in-out,
    border-color 0.6s ease-in-out,
    background 0.6s ease-in-out;

  &:hover {
    background: $link-color;
    border-color: $link-color;
    box-shadow: $default-box-shadow;
    color: #fff;
  }
}

.video-container {
  overflow: hidden;
  position: relative;
  width:100%;
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}