// Masthead
//
// Super small header above the content for site name and short description.

.masthead {
  margin-bottom: 3rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.masthead-title {
  color: $gray-5;
  margin-bottom: 0;
  margin-top: 0;

  a {
    color: inherit;
  }

  small {
    font-size: 75%;
    font-weight: 400;
    opacity: 0.5;
  }
}
